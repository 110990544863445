export const WORDS_PM = [
  'bigblue',
  'delfino',
  'frigate',
  'halberd',
  'lylat',
  'norfair',
  'onett',
  'saffron',
  'temple',
  'randall',

  'peach',
  'bowser',
  'sheik',
  'zelda',
  'falco',
  'marth',
  'ganon',
  'popo',
  'nana',
  'wario',
  'snake',
  'ivysaur',
  'zard',
  'diddy',
  'lucas',
  'sonic',
  'dedede',
  'olimar',
  'lucario',
  'tink',
  'wolf',
  'mewtwo',
  'knuckles',

  'sideb',
  'tranq',
  'lcancel',
  'glair',
  'gqfdl',
  'shffl',
  'jcgrab',
  'galint',
  'wdoos',
  'dacus',
  'trip',
  'glide',
  'cstick',
  'tapjump',
  'pushoff',

  'cape',
  'pkfire',
  'wobble',
  'desync',
  'iceshot',
  'iceberg',
  'needle',
  'chain',
  'vanish',
  'chef',
  'fire',
  'judge',
  'bacon',
  'bucket',
  'octopus',
  'blazer',
  'counter',
  'pill',
  'turnip',
  'turnips',
  'stitch',
  'doteyes',
  'float',
  'bomber',
  'parasol',
  'banana',
  'bubble',
  'disable',
  'aether',
  'bite',
  'chomp',
  'bike',
  'waft',
  'puffup',
  'gunship',
  'whistle',
  'gyro',
  'grenade',
  'cypher',
  'feint',

  'balc',
  'balcy',
  'olympus',
  'theatre',
  'paragon',
  'riptide',
  'swanner',
  'albion',
  'sktar',
  'contra',
  'vgbc',
  'gimr',
  'aposl',
  'wombat',

  'legacy',
  'magus',
  'jiang',
  'shell',
  'yeroc',
  'shanus',
  'cmart',
  'pmdt',
  'motobug',
  'wisely',
  'virum',
  'pmnexus',
  'bird',
  'fingy',

  'hbox',
  'sosa',
  'fuzz',
  'envy',
  'mrlz',
  'aero',
  'blue',
  'aion',
  'luck',
  'mask',
  'cala',
  'orly',
  'narq',
  'wyld',
  'qtip',
  'comb',
  'yono',
  'nogh',
  'darc',
  'plup',
  'aklo',
  'llod',
  'kage',
  'dehf',
  'redd',
  'dart',
  'ppmd',
  'josev',
  'suvir',
  'sothe',
  'kycse',
  'flarp',
  'sneez',
  'poilu',
  'flipp',
  'tealz',
  'sabre',
  'altan',
  'blank',
  'boiko',
  'aidan',
  'bongo',
  'dingo',
  'gallo',
  'chevy',
  'jfyst',
  'goode',
  'morks',
  'loyal',
  'jaden',
  'zhime',
  'blitz',
  'pelca',
  'tombo',
  'denti',
  'frost',
  'venom',
  'spark',
  'rishi',
  'syrox',
  'lucky',
  'wizzy',
  'mango',
  'weonx',
  'switch',
  'pikmon',
  'twisty',
  'connor',
  'silver',
  'ivayne',
  'ripple',
  'oracle',
  'phresh',
  'anther',
  'frozen',
  'leffen',
  'armada',
  'colbol',
  'chudat',
  'stango',
  'sharkz',
  'jeapie',
  'vortex',
  'junebug',
  'nezergy',
  'malachi',
  'techboy',
  'dirtboy',
  'bubbles',
  'copykat',
  'metroid',
  'forrest',
  'chillin',
]
