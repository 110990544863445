export const WORDS_BRAWL = [
  'bigblue',
  'delfino',
  'frigate',
  'halberd',
  'lylat',
  'norfair',
  'onett',
  'temple',

  'peach',
  'bowser',
  'sheik',
  'zelda',
  'falco',
  'marth',
  'ganon',
  'popo',
  'nana',
  'wario',
  'snake',
  'ivysaur',
  'zard',
  'diddy',
  'lucas',
  'sonic',
  'dedede',
  'olimar',
  'lucario',
  'tink',
  'wolf',

  'tabuu',
  'porky',
  'petey',
  'duon',
  'alloy',

  'barrel',
  'bobomb',
  'bonsly',
  'bumper',
  'capsule',
  'crate',
  'cube',
  'dekunut',
  'dragoon',
  'freezie',
  'peanut',
  'pellet',
  'pitfall',
  'sandbag',
  'spring',
  'timer',
  'trophy',
  'unira',

  'sideb',
  'dacus',
  'trip',
  'glide',
  'cstick',
  'edgehog',
  'wifi',
  'tapjump',
  'zdrop',

  'cape',
  'missile',
  'pkfire',
  'pound',
  'desync',
  'iceshot',
  'iceberg',
  'needles',
  'needle',
  'chain',
  'vanish',
  'chef',
  'fire',
  'bacon',
  'judge',
  'bucket',
  'octopus',
  'counter',
  'turnip',
  'turnips',
  'stitch',
  'doteyes',
  'float',
  'bomber',
  'parasol',
  'beam',
  'sword',
  'banana',
  'bubble',
  'aether',
  'bite',
  'chomp',
  'bike',
  'waft',
  'puffup',
  'gunship',
  'whistle',
  'fludd',
  'gyro',
  'grenade',
  'cypher',
  'feint',

  'whobo',
  'sktar',
  'xanadu',
  'gimr',
  'aposl',
  'goml',
  'tojoe',
  'tiopro',

  'gnes',
  'dehf',
  'adhd',
  'esam',
  'quik',
  'rain',
  'edge',
  'leon',
  'dojo',
  'lain',
  'domo',
  'azen',
  'cort',
  'espy',
  'kain',
  'poyo',
  'bike',
  'figy',
  'cody',
  'dabuz',
  'nakat',
  'otori',
  'gluto',
  'fatal',
  'brood',
  'choco',
  'denti',
  'masha',
  'trela',
  'earth',
  'dalki',
  'cross',
  'shugo',
  'candy',
  'havok',
  'plank',
  'shaky',
  'orion',
  'delux',
  'mekos',
  'coney',
  'pelca',
  'zeton',
  'hudet',
  'gagga',
  'anther',
  'vinnie',
  'kakera',
  'tyrant',
  'maguro',
  'atomsk',
  'chudat',
  'shadow',
  'kismet',
  'gardex',
  'junebug',
  'masashi',
  'seibrik',
  'fiction',
  'spammer',
  'ksizzle',
  'koolaid',
  'malcolm',
  'hinkage',
]
