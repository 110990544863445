export const FLASH_VALID_GUESSES = [
  'sekanor',
  'chaos',
  'marvin',
  'nstinct',
  'ppstyle',
  'zalozis',
  'blossom',
  'jammy',
  'drarky',
  'mxzxz',
  'hanaj',
  'mkzair',
  'zair',
  'xenn',
  'kingpaw',
  'chaunch',
  'corvid',
  'kylos',
  'jaiper',
  'agaterf',
  'agate',
  'lite',
  'miskeen',
  'hitsu',
  'mtsmash',
  'tzogc',
  'jorrja',
  'ironh',
  'jaxler',
  'tsobey',
  'godra',
  'auraka',
  'titanmg',
  'loganz',
  'lilmac',
  'amare',
  'acedota',
  'lpoool',
  'ninwing',
  'caveboy',
  'emmdash',
  'tehlamp',
  'koleris',
  'benzgmf',
  'leafyon',
  'kewkage',
  'finatic',
  'mlcn',
  'neogamr',
  'niasty',
  'hxey',
  'swoosh',
  'piclive',
  'mpaxxy',
  'lorga',
  'dswift',
  'apollo',
  'carlxas',
  'eric',
  'idunno',
  'envioux',
  'sonveku',
  'thumbs',
  'visq',
  'nexat',
  'gonza',
  'wooy',
  'xalphax',
  'kevinhc',
  'atlgoku',
  'udime',
  'slimex',
  'ibeevil',
  'cyok',
  'dknight',
  'jotagee',
  'eliashm',
  'joshnh',
  'loushi',
  'burkain',
  'altr',
  'yaikel',
  'waigee',
  'ezrarod',
  'arsenec',
  'sparty',
  'eldog',
  'tson',
  'bomono',
  'kana',
  'mrfap',
  'maven',
  'tac',
  'iffmf',
  'xathoz',
  'hokeh',
  'slaypes',
  'blublur',
  'iepicxd',
  'bynny',
  'supersr',
  'zaxxor',
  'minua',
  'knavi',
  'jobu',
  'sodd',
  'gionnic',
  'naryx',
  'piepei',
  'drflash',
  'jaim',
  'gdom',
  'prayr',
  'afroid',
  'ninwing',
  'ordrint',
  'jdmv',
  'mins',
  'yaro',
  'kurt',
  'tekz',
  'segaman',
  'andres',
  'daddy',
  'tsukky',
  'bbmfan',
  'thedark',
  'zxcas',
  'aldor',
  'johnsj',
  'lyght',
  'ajroy',
  'danteno',
  'foxx',
  'blazelk',
  'aururu',
  'tplvfmx',
  'fwcrash',
  'tekken',
  'lorga',
  'jude',
  'dragon',
  'theblue',
  'idail',
  'carlos',
  'xeratic',
  'blardow',
  'thefelt',
  'artix',
  'skidzz',
  'lidrume',
  'yaminow',
  'herokun',
  'xailuz',
  'twit',
  'hugh',
  'ssims',
  'deusjm',
  'deus',
  'neji',
  'gokuk',
  'karuto',
  'sanic',
  'gabeuu',
  'kizu',
  'solar',
  'flxw',
  'aucious',
  'brann',
  'arsenec',
  'thebestg',
  'siralfa',
  'vridend',
  'raum',
  'maruxx',
  'yoshyj',
  'lugian',
  'smirby',
  'frasky',
  'saiaku',
  'idoge',
  'yupia',
  'vinny',
  'jakepro',
  'foxxtv',
  'nanyz',
  'akan',
  'pablito',
  'niak',
  'hdog',
  'basstn',
  'ztrunks',
  'ragedo',
  'lermonz',
  'fotzu',
  'sinux',
  'alexiov',
  'beesea',
  'veslime',
  'ayat',
  'yungroy',
  'hermes',
  'mrspam',
  'bendel',
  'ladner',
  'polywow',
  'jonii',
  'strixia',
  'rychu',
  'bits',
  'delipa',
  'rbiii',
  'skoopee',
  'caleb',
  'eruitog',
  'eruit',
  'byvans',
  'vazer',
  'machry',
  'gerachu',
  'edder',
  'mrkeys',
  'colaw',
  'metaexe',
  'eien',
  'tofuboi',
  'motazzz',
  'smashec',
  'thomb',
  'tcred',
  'xedge',
  'wulfx',
  'voidof',
  'realaj',
  'karadek',
  'nyano',
  'assaj',
  'nakvoti',
  'mathsu',
  'trifeb',
  'frogman',
  'vulpae',
  'dobirf',
  'dgaf',
  'toonish',
  'valatic',
  'dayer',
  'spitcat',
  'tristy',
  'nekoply',
  'thepuff',
  'celto',
  'juliem',
  'haru',
  'mailman',
  'matt',
  'jksonic',
  'yakumo',
  'astico',
  'blinkz',
  'papold',
  'markyg',
  'yoshij',
  'leidyf',
  'khgamer',
  'codu',
  'axazyum',
  'fulmen',
  'briamty',
  'spyro',
  'aldrin',
  'dexall',
  'justvo',
  'harpu',
  'kaiten',
  'jugudy',
  'lioeze',
  'destrax',
  'pikart',
  'legenda',
  'gokame',
  'ircatz',
  'edujosu',
  'elix',
  'evboy',
  'aivorys',
  'nirve',
  'dudeman',
  'arturo',
  'gaben',
  'alzitra',
  'hobiew',
  'antoneo',
  'porky',
  'mari',
  'kerzyq',
  'kerzy',
  'djultra',
  'rgostik',
  'bentl',
  'blorzex',
  'poshide',
  'vavakun',
  'japzer',
  'keegan',
  'ivgorm',
  'favio',
  'prizrak',
  'tajhir',
  'derpaa',
  'stooky',
  'xander',
  'dairo',
  'keeko',
  'rsjx',
  'higsi',
  'tsemsut',
  'zakoxd',
  'kimoski',
  'atraxa',
  'leotour',
  'andrik',
  'gghosty',
  'kazu',
  'junip',
  'mikoto',
  'sktt',
  'tylertm',
  'zalor',
  'zalorx',
  'danix',
  'zachor',
  'tovan',
  'vieux',
  'bowler',
  'jaccel',
  'gtgold',
  'aerfair',
  'osan',
  'mudkip',
  'makken',
  'iloji',
  'wizdood',
  'voltric',
  'uffda',
  'imeden',
  'henoz',
  'gyasim',
  'catteh',
  'jordan',
  'jefm',
  'alexd',
  'shwick',
  'tkjmac',
  'wuhw',
  'sirus',
  'mikeya',
  'jalstar',
  'nicoh',
  'irun',
  'maestro',
  'kyvixs',
  'bluspn',
  'drevis',
  'stoop',
  'itsjudo',
  'isack',
  'izako',
  'ssims',
  'moshi',
  'wahhhe',
  'pootis',
  'joguiza',
  'xtax',
  'tillur',
  'radzo',
  'tlgamer',
  'charv',
  'glyphe',
  'imabud',
  'omla',
  'dtyoshi',
  'nienty',
  'zeino',
  'prof',
  'tpsfm',
  'vinnyk',
  'emsjr',
  'croobs',
  'gainy',
  'hani',
  'nichok',
  'kuro',
  'riley',
  'faulks',
  'tyxtwo',
  'sintha',
  'walkerp',
  'arclen',
  'triler',
  'vree',
  'melonxd',
  'itkorea',
  'wilsona',
  'pablo',
  'wulgr',
  'joakoso',
  'jetrr',
  'nukekin',
  'trabp',
  'umbrus',
  'scroal',
  'jpeg',
  'kenyik',
  'tedtdh',
  'oglava',
  'piggeaz',
  'jwatt',
  'alphan',
  'drakoto',
  'faker',
  'marc',
  'totcy',
  'jaequan',
  'bino',
  'aizey',
  'bocchi',
  'zynccc',
  'gktails',
  'skaarj',
  'megabit',
  'sweetbr',
  'darkr',
  'asalem',
  'romello',
  'nryu',
  'yinyang',
  'kyato',
  'keithf',
  'hiro',
  'biannua',
  'felipe',
  'kevdog',
  'patrick',
  'zackie',
  'ppter',
  'arrowxx',
  'sansabe',
  'rutoni',
  'lefdety',
  'nogui',
  'kenshii',
  'itzzmg',
  'salti',
  'bifrons',
  'josrrm',
  'livter',
  'treflo',
  'swozup',
  'rojae',
  'solink',
  'neisus',
]
