export const WORDS_MELEE = [
  'bigblue',
  'onett',
  'temple',
  'venom',
  'randall',
  'whispy',

  'peach',
  'bowser',
  'sheik',
  'zelda',
  'pichu',
  'falco',
  'marth',
  'yink',
  'ganon',
  'popo',
  'nana',
  'mewtwo',

  'apple',
  'barrel',
  'bobomb',
  'capsule',
  'crate',
  'flipper',
  'freezie',
  'trophy',

  'sideb',
  'lcancel',
  'shffl',
  'jcgrab',
  'jcshine',
  'galint',
  'wdoos',
  'asdi',
  'cstick',
  'edgehog',
  'doraki',
  'zdrop',

  'tdrop',
  'qdrop',
  'floss',
  'vdrop',
  'vcancel',
  'mangle',
  'mangles',
  'optimal',
  'arduino',
  'pode',
  'dockid',
  'bigleff',

  'swallow',
  'cape',
  'pkfire',
  'pound',
  'missile',
  'wobble',
  'belay',
  'iceshot',
  'desync',
  'blazer',
  'counter',
  'chef',
  'fire',
  'bacon',
  'bucket',
  'pill',
  'firebow',
  'needles',
  'needle',
  'chain',
  'vanish',
  'sami',
  'poof',
  'turnip',
  'turnips',
  'stitch',
  'doteyes',
  'float',
  'bomber',
  'parasol',
  'beam',
  'sword',
  'disable',

  'wtfox',
  'beast',
  'moast',
  'same',
  'cream',
  'lacs',
  'heir',
  'paragon',
  'zenith',
  'albion',
  'eglx',
  'slippi',
  'gaylee',
  'octagon',
  'tennis',
  'mpgr',
  'bemi',
  'akaneia',
  'miom',
  'pulse',
  'wisely',
  'riptide',
  'unsure',
  'tiopro',
  'levo',

  'toph',
  'scar',
  'wife',
  'tafo',
  'vish',
  'phil',
  'blur',
  'stude',
  'zetts',
  'slime',
  'radar',

  'fizzi',
  'ludwig',
  'save',
  'ptas',
  'samox',
  'wheat',
  'edwin',
  'bensw',
  'prog',
  'epengu',
  'clippi',
  'blippi',
  'diet',
  'crystal',
  'beyond',
  'sfatjam',
  'kotc',

  'sktar',
  'alukard',
  'dpotg',
  'contra',
  'patti',
  'brando',
  'dhir',
  'bobc',
  'summit',
  'calvin',
  'xanadu',
  'vgbc',
  'gimr',
  'aposl',
  'hflan',
  'goml',
  'tojoe',
  'mazer',

  'ppmd',
  'juan',
  'zuan',
  'hbox',
  'hugs',
  'amsa',
  'jman',
  'jcam',
  'cort',
  'javi',
  'darc',
  'eggm',
  'tope',
  'lord',
  'vanz',
  'ibdw',
  'plup',
  'aklo',
  'medz',
  'azen',
  'llod',
  'moky',
  'slug',
  'isai',
  'zain',
  'spud',
  'qerb',
  'magi',
  'htwa',
  'kage',
  'sora',
  'zamu',
  'slox',
  'aura',
  'trif',
  'mint',
  'timi',
  'boyd',
  'kurv',
  'azel',
  'nagy',
  'duck',
  'fizz',
  'kels',
  'maxy',
  'dehf',
  'dope',
  'redd',
  'reno',
  'dart',
  'zoso',
  'matt',
  'kira',
  'tekk',
  'kpan',
  'sfop',
  'chem',
  'blue',
  'esam',
  'lint',
  'soft',
  'vist',
  'shino',
  'flash',
  'logan',
  'mango',
  'lucky',
  'amsah',
  'jmook',
  'jflex',
  'jsalt',
  'crush',
  'spark',
  'rishi',
  'squid',
  'franz',
  'nicki',
  'rocky',
  'abate',
  'syrox',
  'reeve',
  'bones',
  'swift',
  'dacky',
  'okayp',
  'zuppy',
  'krudo',
  'wally',
  'faust',
  'goomy',
  'tempo',
  'hanky',
  'chape',
  'snowy',
  'bobby',
  'humpe',
  'renzo',
  'raoul',
  'rikzz',
  'sharp',
  'mahie',
  'kingu',
  'vwins',
  'gucci',
  'weonx',
  'azusa',
  'gravy',
  'remen',
  'ralph',
  'jerry',
  'kaeon',
  'bzimm',
  'sanne',
  'wizzy',
  'jaden',
  'bimbo',
  'santi',
  'trail',
  'jiano',
  'mikeg',
  'eddie',
  'lovage',
  'leffen',
  'armada',
  'ginger',
  'chudat',
  'gahtzu',
  'setchi',
  'stango',
  'colbol',
  'kalvar',
  'frenzy',
  'polish',
  'umarth',
  'sharkz',
  'chango',
  'wevans',
  'jeapie',
  'legend',
  'zgetto',
  'anther',
  'cyrain',
  'hyprid',
  'lucien',
  'raynex',
  'reaper',
  'twitch',
  'vortex',
  'nebbii',
  'iceman',
  'johnny',
  'zealot',
  'justus',
  'connor',
  'peepee',
  'pcchris',
  'wobbles',
  'chillin',
  'forward',
  'ryobeat',
  'fiction',
  'pewpewu',
  'bananas',
  'fatgoku',
  'drephen',
  'michael',
  'forrest',
  'soonsay',
  'kuyashi',
  'pricent',
  'kodorin',
  'junebug',
  'android',
  'cactuar',
  'mvlvchi',
  'theking',
  'swedish',
  'inngenn',
  'unknown',
  'runriot',
  'abbearv',
  'levingy',
  'masashi',
  'darrell',
  'rudolph',
  'zealous',
  'lordhdl',
  'okamibw',
  'killaor',
  'tekkenz',
]
