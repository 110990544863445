export const WORDS_FLASH = [
  'saffron',
  'desk',

  'peach',
  'bowser',
  'wario',
  'zelda',
  'sheik',
  'falco',
  'marth',
  'popo',
  'nana',
  'sonic',
  'pacman',
  'sora',
  'ganon',
  'pichu',
  'lucario',
  'simon',
  'isaac',
  'lloyd',
  'luffy',
  'tails',
  'goku',
  'naruto',
  'ichigo',
  'waluigi',
  'krystal',
  'rayman',

  'bobomb',
  'flipper',
  'beamrod',
  'bumper',
  'capsule',
  'dekunut',
  'freezie',
  'itembag',
  'medusa',
  'pitfall',
  'unira',

  'cape',
  'tornado',
  'counter',
  'chef',
  'bacon',
  'judge',
  'fire',
  'stop',
  'haste',
  'warp',
  'jetpack',
  'pickup',
  'arcade',
  'blaster',
  'arwing',
  'kiblast',
  'kiaoken',
  'shffl',
  'gazan',
  'mugetsu',
  'vine',
  'move',
  'scoop',
  'gaia',
  'sniper',
  'tempest',
  'feint',
  'pkfire',
  'pacjump',
  'hydrant',
  'bomber',
  'parasol',
  'agility',
  'lockjaw',
  'vortex',
  'plum',
  'bzzit',
  'hadoken',
  'shoryu',
  'tatsu',
  'focus',
  'missile',
  'needle',
  'needles',
  'vanish',
  'cross',
  'airlift',
  'chomp',
  'waft',
  'oddish',
  'stitch',

  'hypest',
  'exodus',
  'bowlers',
  'beta',

  'demigod',
  'king',
  'knight',
  'peasant',

  'vash',
  'rage',
  'dany',
  'kyoz',
  'riko',
  'raum',
  'kizu',
  'nine',
  'xenn',
  'zair',
  'jdmn',
  'oreo',
  'stef',
  'hida',
  'velo',
  'arsee',
  'monte',
  'etnom',
  'akame',
  'andro',
  'ragss',
  'river',
  'chaos',
  'ender',
  'blade',
  'agate',
  'brann',
  'pixel',
  'henoz',
  'uffda',
  'inari',
  'iloji',
  'jammy',
  'kylos',
  'mxzxz',
  'tlord',
  'hanaj',
  'piano',
  'godly',
  'bbmfan',
  'corvid',
  'jaiper',
  'zroshi',
  'mkzair',
  'bowler',
  'tillur',
  'yakumo',
  'makken',
  'drarky',
  'marvin',
  'lunary',
  'appley',
  'andres',
  'helios',
  'astral',
  'wrxjoey',
  'nstinct',
  'ppstyle',
  'zalozis',
  'blossom',
  'mgultra',
  'lermonz',
  'flavien',
  'charvee',
  'alpharc',
  'aucious',
  'kingpaw',
  'chaunch',
  'riverdb',
  'acedota',
  'jiracha',
]
