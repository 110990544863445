export const WORDS_BASE = [
  'sakurai',
  'sandbag',
  'mario',
  'link',
  'samus',
  'yoshi',
  'kirby',
  'pika',
  'luigi',
  'ness',
  'falcon',
  'puff',

  'raygun',
  'hammer',
  'starrod',

  'melee',
  'flash',
  'brawl',
  'projectm',
  'minus',
  'remix',
  'iwata',
  'timer',
  'spawn',
  'smurf',
  'ledge',
  'ledges',
  'upair',
  'stall',
  'mixup',
  'mixups',
  'drift',
  'stock',
  'stocks',
  'john',
  'johns',
  'smash',
  'wombo',
  'combo',
  'combos',
  'pools',
  'pivot',
  'notch',
  'zdrop',
  'zoner',
  'zoners',
  'zoning',
  'dodge',
  'clone',
  'clones',
  'ditto',
  'dittos',
  'plank',
  'roll',
  'spike',
  'spikes',
  'stale',
  'staling',
  'throw',
  'throws',
  'ftilt',
  'downb',
  'frame',
  'frames',
  'reads',
  'cargo',
  'taunt',
  'taunts',
  'parry',
  'armor',
  'tjolt',
  'meaty',
  'reset',
  'crews',
  'getup',
  'setup',
  'setups',
  'scrub',
  'scrubs',
  'trade',
  'trades',
  'whiff',
  'whiffs',
  'major',
  'majors',
  'local',
  'locals',
  'tiers',
  'lowtier',
  'midtier',
  'stage',
  'stages',
  'beefy',
  'randy',
  'mafia',
  'toad',
  'toads',
  'birdo',
  'birdos',
  'items',
  'bombs',
  'range',
  'salty',
  'score',
  'trash',
  'clank',
  'cling',
  'fundies',
  'hitstun',
  'endlag',
  'hazards',
  'buffer',
  'tether',
  'tether',
  'meteors',
  'pummel',
  'pummels',
  'tech',
  'grab',
  'grabs',
  'item',
  'aerial',
  'aerials',
  'cancel',
  'cancels',
  'dash',
  'jump',
  'jumps',
  'nair',
  'bair',
  'fair',
  'dair',
  'downair',
  'backair',
  'crew',
  'stocks',
  'rest',
  'punish',
  'topplat',
  'triplat',
  'nojohns',
  'shine',
  'clip',
  'clips',
  'clipped',
  'bodied',
  'homie',
  'homies',
  'dubs',
  'doubles',
  'byoc',
  'matchup',
  'attack',
  'special',
  'homerun',
  'carried',

  'crouch',
  'shield',
  'shields',
  'fullhop',
  'spacing',
  'spacie',
  'spacies',
  'hitbox',
  'hurtbox',
  'percent',
  'weakhit',
  'dsmash',
  'fsmash',
  'upsmash',
  'neutral',
  'corner',
  'misfire',
  'hitlag',
  'iasa',
  'phantom',
  'pillar',
  'sexkick',
  'tumble',
  'foxtrot',
  'iframe',
  'iframes',
  'labber',
  'labbers',
  'labbing',
  'modder',
  'modders',
  'modding',
  'claw',
  'plus',
  'poke',
  'poking',
  'gimp',
  'gimps',
  'gimping',
  'shark',
  'grind',
  'ranking',
  'teeter',
  'trapper',
  'banger',
  'mash',
  'masher',
  'smasher',

  'firefox',
  'inhale',
  'yoyo',
  'pkflash',
  'bomb',
  'bombs',
  'thunder',
  'tornado',
  'cyclone',
  'punch',
  'charge',
  'egglay',
  'eggbomb',
  'eggroll',
  'stone',
  'sing',
  'blaster',
  'rollout',

  'winners',
  'losers',
  'discord',
  'twitch',
  'youtube',
  'smashgg',
  'goat',

  'genesis',
  'boback',
  'apex',
  'panda',
  'anthers',
  'netplay',
  'metafy',
  'tloc',
  'clash',
  'chia',
  'andy',

  'kuyachi',
  'randle',
  'grsmash',
]
