export const WORDS_DAILY = [
  'getup',
  'whiff',
  'samox',
  'tabuu',
  'judge',
  'fizzi',
  'wombo',
  'johns',
  'shffl',
  'marss',
  'sabre',
  'tacos',
  'jayde',
  'hflan',
  'coney',
  'ditto',
  'marth',
  'iwata',
  'gamer',
  'tjolt',
  'plank',
  'unira',
  'isai',
  'arsee',
  'twitch',
  'riptide',
  'randall',
  'gaylee',
  'wisely',
  'ryuga',
  'ranking',
  'taunts',
  'myles',
  'spark',
  'mekos',
  'ludwig',
  'grenade',
  'mkzair',
  'sothe',
  'misfire',
  'azen',
  'score',
  'shuton',
  'setup',
  'steve',
  'flavien',
  'crouch',
  'range',
  'norfair',
  'agility',
  'ouch',
  'atomsk',
  'motobug',
  'kuro',
  'punish',
  'bananas',
  'wiifit',
  'getup',
  'remi',
  'prodigy',
  'notch',
  'endlag',
  'locus',
  'rocky',
  'chango',
  'kola',
  'grabs',
  'bodied',
  'ledges',
  'moast',
  'tilde',
  'chape',
  'tamim',
  'galeem',
  'unova',
  'bzimm',
  'adhd',
  'fundies',
  'hitstun',
  'zain',
  'modder',
  'sisters',
  'terry',
  'domo',
  'pikmon',
  'tiopro',
  'venom',
  'lucina',
  'jcgrab',
  'zoning',
  'switch',
  'tyrant',
  'hikaru',
  'spawn',
  'jaaahsh',
  'tink',
  'homie',
  'pivot',
  'mythra',
  'narq',
  'tether',
  'bobomb',
  'dair',
  'lovage',
  'cancels',
  'labbing',
  'nabbit',
  'staling',
  'cancel',
  'hero',
  'clipped',
  'quidd',
  'toast',
  'soonsay',
  'tornado',
  'dhir',
  'quik',
  'ryobeat',
  'sideb',
  'rags',
  'cactuar',
  'projectm',
  'shyguys',
  'wobble',
  'leon',
  'akame',
  'villy',
  'stage',
  'phantom',
  'unsure',
  'claw',
  'saltone',
  'raflow',
  'ibdw',
  'sectorz',
  'jflex',
  'pyra',
  'ixis',
  'flarp',
  'ptooie',
  'javi',
  'local',
  'reads',
  'reset',
  'atelier',
  'sktar',
  'clip',
  'youtube',
  'scar',
  'lunary',
  'zetts',
  'bobomb',
  'etnom',
  'scrubs',
  'majors',
  'michael',
  'beast',
  'dacus',
  'dedede',
  'lacs',
  'remix',
  'midtier',
  'zinoto',
  'puff',
  'gallo',
  'upsmash',
  'meaty',
  'spike',
  'raynex',
  'panda',
  'calvin',
  'atata',
  'dubs',
  'cream',
  'myran',
  'shield',
  'plup',
  'krudo',
  'poggers',
  'duon',
  'wizzy',
  'fire',
  'roll',
  'sing',
  'modders',
  'techboy',
  'tapjump',
  'kameme',
  'fiction',
  'sisqui',
  'okayp',
  'webbjp',
  'trash',
  'tatsu',
  'grind',
  'triplat',
  'toph',
  'gimping',
  'gimp',
  'john',
  'zenyou',
  'freezie',
  'duck',
  'mixups',
  'shine',
  'sandbag',
  'metroid',
  'hitlag',
  'shoryu',
  'jump',
  'pokelam',
  'peabnut',
  'yoshi',
  'wadi',
  'pewpewu',
  'raoul',
  'nezergy',
  'flash',
  'setchi',
  'ppmd',
  'olimar',
  'downb',
  'shino',
  'goat',
  'nickc',
  'wrath',
  'findmii',
  'simon',
  'ness',
  'rain',
  'kome',
  'umeki',
  'riddles',
  'chillin',
  'spikes',
  'chain',
  'hydrant',
  'pulse',
  'wuhu',
  'yonni',
  'matchup',
  'flash',
  'bidou',
  'blur',
  'gackt',
  'bemi',
  'dehf',
  'lcancel',
  'bobby',
  'pricent',
  'armor',
  'marx',
  'puppeh',
  'miom',
  'ivysaur',
  'save',
  'monte',
  'azel',
  'pcchris',
  'otori',
  'link',
  'phil',
  'zuppy',
  'item',
  'gnes',
  'lemmy',
  'varun',
  'anther',
  'peasant',
  'stages',
  'hazards',
  'stroder',
  'blossom',
  'fludd',
  'dragoon',
  'octagon',
  'cosmos',
  'firefox',
  'thunder',
  'throws',
  'colbol',
  'muteace',
  'unknown',
  'prince',
  'cort',
  'dash',
  'bombs',
  'yuzu',
  'runriot',
  'awestin',
  'strike',
  'frenzy',
  'aaron',
  'crate',
  'momocon',
  'kira',
  'alpharc',
  'elexiao',
  'kycse',
  'zard',
  'vash',
  'peepee',
  'dart',
  'wrxjoey',
  'krool',
  'kalvar',
  'kage',
  'bomb',
  'dodge',
  'clones',
  'jsalt',
  'boback',
  'mvlvchi',
  'sonic',
  'bigblue',
  'pillar',
  'stude',
  'waft',
  'crew',
  'petey',
  'dabuz',
  'demigod',
  'tempo',
  'clone',
  'rizeasu',
  'lucario',
  'xenn',
  'throw',
  'zoner',
  'bear',
  'johnny',
  'moky',
  'swift',
  'llod',
  'discord',
  'same',
  'waluigi',
  'grape',
  'birdos',
  'trades',
  'zdrop',
  'glitch',
  'cling',
  'kodorin',
  'trip',
  'wally',
  'tumble',
  'drephen',
  'pacland',
  'tiers',
  'vish',
  'hype',
  'percent',
  'eddie',
  'inari',
  'pools',
  'zamu',
  'junior',
  'randy',
  'crews',
  'tweek',
  'turnip',
  'mafia',
  'pummel',
  'falcon',
  'chag',
  'choco',
  'pkfire',
  'dsmash',
  'buffer',
  'daisy',
  'krystal',
  'aerials',
  'android',
  'shark',
  'onett',
  'mrlz',
  'delfino',
  'nogh',
  'cargo',
  'tech',
  'combos',
  'gyro',
  'sonix',
  'chudat',
  'temple',
  'kept',
  'konga',
  'tails',
  'plant',
  'brosa',
  'arduino',
  'void',
  'spacing',
  'plus',
  'cyclone',
  'gucci',
  'paragon',
  'kuyashi',
  'stitch',
  'suplex',
  'poilu',
  'slippi',
  'rest',
  'upair',
  'yeti',
  'grsmash',
  'nojohns',
  'lucas',
  'aklo',
  'kalos',
  'etsuji',
  'norfair',
  'zgetto',
  'snake',
  'light',
  'iasa',
  'hammer',
  'leffen',
  'galleom',
  'iframe',
  'eiha',
  'poke',
  'trif',
  'armada',
  'rollout',
  'starrod',
  'needle',
  'losers',
  'zoners',
  'tennis',
  'sami',
  'galaga',
  'bacon',
  'space',
  'palu',
  'sanne',
  'venom',
  'stock',
  'watch',
  'clash',
  'turnips',
  'mkleo',
  'shotos',
  'reeve',
  'pacman',
  'homies',
  'magi',
  'bowler',
  'pmdt',
  'sora',
  'legend',
  'bombs',
  'yoyo',
  'smash',
  'frame',
  'shky',
  'scend',
  'goml',
  'mpgr',
  'albion',
  'kyoz',
  'ridley',
  'lylat',
  'goblin',
  'pogchamp',
  'beefy',
  'halberd',
  'charles',
  'santi',
  'nicko',
  'otori',
  'maister',
  'byleth',
  'theking',
  'brawl',
  'bombchu',
  'metafy',
  'bobc',
  'jake',
  'balc',
  'fsmash',
  'shoto',
  'zackray',
  'pkflash',
  'topplat',
  'isaac',
  'robin',
  'umebura',
  'wtfox',
  'bigd',
  'jakal',
  'wobbles',
  'meru',
  'forrest',
  'azen',
  'locals',
  'maguro',
  'ftilt',
  'corrin',
  'sexkick',
  'saffron',
  'smurf',
  'bayo',
  'larry',
  'mahie',
  'ender',
  'lmbm',
  'timer',
  'jcshine',
  'inkling',
  'trade',
  'modding',
  'forward',
  'wolf',
  'rayman',
  'frost',
  'doteyes',
  'zenith',
  'stocks',
  'rosa',
  'fatal',
  'fatgoku',
  'mistake',
  'doubles',
  'albion',
  'grab',
  'joker',
  'lain',
  'vwins',
  'laid',
  'whydo',
  'syrup',
  'scrub',
  'netplay',
  'chrom',
  'dacky',
  'nietono',
  'rikzz',
  'sandbag',
  'bowlers',
  'grayson',
  'phresh',
  'iframes',
  'brood',
  'renzo',
  'moxi',
  'dakpo',
  'apex',
  'redd',
  'midgar',
  'neutral',
  'setups',
  'labbers',
  'franz',
  'nair',
  'fair',
  'skyjay',
  'chef',
  'wario',
  'icymist',
  'pummels',
  'meme',
  'maeda',
  'lurr',
  'genesis',
  'nakat',
  'kazuya',
  'byoc',
  'ledge',
  'fullhop',
  'jumps',
  'tafo',
  'andy',
  'gunner',
  'inhale',
  'cstick',
  'raito',
  'minus',
  'masashi',
  'tealz',
  'melee',
  'river',
  'whiffs',
  'summit',
  'hitbox',
  'lyric',
  'weakhit',
  'trump',
  'stale',
  'abate',
  'weonx',
  'asdi',
  'aion',
  'humpe',
  'aerial',
  'capsule',
  'shields',
  'sharp',
  'brawler',
  'edge',
  'mario',
  'wife',
  'jmook',
  'amsah',
  'qtip',
  'bowler',
  'brood',
  'kuyachi',
  'stall',
  'galint',
  'justus',
  'zaki',
  'glide',
  'bike',
  'zealot',
  'dirtboy',
  'heir',
  'float',
  'gimps',
  'dekunut',
  'hanky',
  'cyrain',
  'birdo',
  'samus',
  'diddy',
  'banjo',
  'chia',
  'smashgg',
  'akaneia',
  'mekos',
  'lights',
  'sosa',
  'chevy',
  'anthers',
  'pmnexus',
  'cucco',
  'lima',
  'twisty',
  'lucky',
  'meteors',
  'rfang',
  'zair',
  'mango',
  'iceman',
  'tether',
  'darc',
  'bucket',
  'logan',
  'proto',
  'poking',
  'stocks',
  'faust',
  'nicki',
  'swedish',
  'ptas',
  'leon',
  'tojoe',
  'dittos',
  'gahtzu',
  'toads',
  'minmin',
  'cloud',
  'kingu',
  'zomba',
  'zdrop',
  'drift',
  'atomsk',
  'pika',
  'virum',
  'foxtrot',
  'hbox',
  'rishi',
  'goku',
  'crush',
  'bones',
  'spacies',
  'alloy',
  'levo',
  'jerry',
  'exodus',
  'scatt',
  'theatre',
  'salty',
  'inngenn',
  'mugen',
  'wifi',
  'teeter',
  'jams',
  'alvin',
  'bair',
  'items',
  'pgru',
  'shyguy',
  'esam',
  'combo',
  'taunt',
  'doraki',
  'desync',
  'olympus',
  'oracle',
  'spacie',
  'corner',
  'pitfall',
  'ranai',
  'josev',
  'eglx',
  'polish',
  'labber',
  'junebug',
  'drill',
  'blitz',
  'timer',
  'clips',
  'rivers',
  'parry',
  'ikep',
  'dojo',
  'flipp',
  'dharkon',
  'syrox',
  'mabel',
  'pelca',
  'kakera',
  'ramses',
  'goomy',
  'winners',
  'tillur',
  'frames',
  'nakat',
  'kurama',
  'optimal',
  'zcancel',
  'edgehog',
  'frigate',
  'tloc',
  'clank',
  'major',
  'ginger',
  'hurtbox',
  'hadoken',
  'wendy',
  'needles',
  'porky',
  'seibrik',
  'raygun',
  'lowtier',
  'shulk',
  'luigi',
  'vinnie',
  'slug',
  'kirby',
  'trela',
  'hugs',
  'amsa',
  'mixup',
  'elegant',
  'oreclub',
  'richter',
  'malachi',
  'elytra',
  'sinji',
  'dabuz',
]
