export const WORDS_64 = [
  'barrel',
  'bobomb',
  'bumper',
  'capsule',
  'crate',

  'saffron',
  'sectorz',
  'zebes',
  'whispy',

  'zcancel',
  'edgehog',

  'snosa',
  'kanto',
  'kansai',
  'tacna',
  'dpotg',
  'eglx',
  'goml',

  'isai',
  'zero',
  'kort',
  'firo',
  'cobr',
  'nacl',
  'near',
  'dogg',
  'pete',
  'kysk',
  'bard',
  'jayr',
  'paco',
  'dtan',
  'goon',
  'loto',
  'czar',
  'hiyo',
  'supa',
  'razz',
  'alvin',
  'tacos',
  'revan',
  'banze',
  'sotoh',
  'gyaki',
  'brody',
  'joshi',
  'finio',
  'hydra',
  'janco',
  'banze',
  'derek',
  'sextc',
  'blink',
  'jason',
  'lowww',
  'wario',
  'joshi',
  'prince',
  'shears',
  'robert',
  'dajjal',
  'spongy',
  'freean',
  'raychu',
  'daniel',
  'horbie',
  'yunque',
  'taimai',
  'killer',
  'jamjar',
  'ybombb',
  'wangera',
  'heropie',
  'shalaka',
  'marbles',
  'wookiee',
  'snorlax',
  'janitor',
  'hipstur',
  'lorenzo',
  'fukurou',
  'preston',
  'hotline',
  'shihman',
  'shizuku',
  'stevieg',
  'madrush',
  'jaimehr',
  'qapples',
]
